import { configureStore } from "@reduxjs/toolkit";
import projectReducer from './Projects/ProjectSlice.tsx'



export const Store = configureStore({
    reducer:{
        project : projectReducer 
    }
})

// return type(typeScriptt helper utility) 
export type RootState = ReturnType<typeof Store.getState>;
// i think it gives the tye of return we are sending -- i think
export type AppDispatch = typeof Store.dispatch