import { Suspense, useEffect, useRef, useState } from 'react'
import './Webdev.css'
import { useInView } from 'react-intersection-observer'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Macbook } from '../3dModels/Macbook.jsx'
import { CameraControls, Environment, Float, OrbitControls } from '@react-three/drei'
import Card from '../Card/Card.jsx'
import { Phone } from '../3dModels/Phone.jsx'
import { useNavigate } from 'react-router-dom'

export default function Webdev(){

    
   const navigate = useNavigate()
    const { ref:ref1 ,  inView:inView1 } = useInView({
        threshold:0,
    })
    const { ref:ref2 ,  inView:inView2 } = useInView({
        threshold:0,

    })
    


    const laptopRef = useRef()



    const [floaz , setFloat] = useState(10)
    const [enable, setEnable] = useState(true)

    useEffect(()=>{
        var laptop = document.getElementById('laptop-canvas')
        laptopRef.current?.reset(true)
        if(inView1){
            console.log("inview1")
            setEnable(true)
            laptop.style.zIndex = 0
            laptopRef.current?.reset(true)
        }
        else if(inView2){
            console.log("inview2")
            setEnable(true) 
            laptop.style.zIndex = 0
            laptopRef.current?.moveTo(...[(window.innerHeight>window.innerWidth)?60:70 , 0 , -50],true)
            // laptopRef.current?.rotate( -20 * DEG2RAD,0, true)
            // laptopRef.current?.setLookAt(...[0,0,0],...[0,0,0],true)
        }
        else{
            console.log("out")
        }
    },[inView1,inView2])


    const phone = useRef()

    // useFrame(()=>{
    // //     phone.current.rotation.x = +1
    //     if(phone.current){}
    // })
    
    

    return(
        <>
            <div className='webdev' id='webel'>
                

                {/* main showcase div that listens to intersection observer */}

                
                    <div className='webdev-parent'>
                        <div className='webdev-child'>
                            <div ref={ref1}  className='webdev-child-under'>
                                <div className='child-under-content'>
                                    <Card content={
                                    <p>
                                    I am adept in TypeScript, JavaScript, React.js, Node.js, Express.js, and PostgreSQL. With this skill set, I specialize in crafting scalable solutions, intuitive user interfaces, robust backend systems, and optimized databases. I thrive on delivering high-quality projects that exceed expectations and drive success.
                                    </p>
                                    // four images
                                    }/>
                                </div>
                            </div>
                            <div ref={ref2} className='webdev-child-under'>
                                <div className='child-under-content'>
                                    <Card content={
                                    <p>I am skilled in Flutter and React Native for cross-platform mobile app development. Leveraging these technologies, I create seamless and responsive mobile applications that run efficiently on both iOS and Android platforms. My focus is on delivering intuitive user experiences and achieving optimal performance
                                        {(inView2)?
                                     <div onClick={()=>{navigate('/projects')}} className='bounce'>View Projects ↓</div>:''}
                                    </p>
                                    // four images
                                    }/>
                                </div>
                                
                            </div>
                            {/* <div ref={ref3} id='r3' className='webdev-child-under'></div>
                            <div ref={ref4} className='webdev-child-under'></div>
                            <div ref={ref5} className='webdev-child-under'></div> */}
                        </div>
                        <div className='webdev-showcase'  id='laptop-canvas' >
                            <Canvas camera={{position:[0, 0, 30],fov:30,zoom:1.2}} >
                                {/* onClick={()=>{document.getElementById('r3').scrollIntoView({behavior:'smooth'})}} */}
                                <mesh>
                                    {/* enableRotate={(window.screen.width < 1024)? false:true} */}
                                    <OrbitControls enabled={false} />
                                    <ambientLight intensity={1}/>
                                    <pointLight position={[10, 5, 10]} intensity={1.5} />
                                    <Suspense fallback={null}>
                                        <Float floatIntensity={floaz} speed={floaz}>
                                            <Macbook  position={ (window.innerHeight>window.innerWidth)? [-1, -10 , -30]:[-10,0,-10]} />
                                        </Float>
                                        <Phone position = {[60,0,-60]}/>
                                    </Suspense>
                                    <Environment preset="apartment" />
                                    <CameraControls enabled={enable}   ref={laptopRef}/>
                                </mesh>
                            </Canvas>
                        </div>
                    </div>
                </div>
            
        </>
    )
}