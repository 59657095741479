import { useNavigate } from 'react-router-dom'
import BackGround from '../../component/3dModels/BackGround/BackGround.jsx'
import Header from '../../component/Header/Header.jsx'
import Hero from '../../component/Hero/Hero'
import Webdev from '../../component/Webdev/Webdev.jsx'

export default function MainPage(){

   const navigate = useNavigate()
    return(
        <div>
            {/* background */}
            <button style={{position:'fixed',right:'2%',bottom:'1%',zIndex:'90',
            backgroundColor:'transparent',color:'white',height:'100px',width:'100px',borderRadius:'50%'}}
            onClick={()=>navigate('/contact')}
            >Contact me</button>
            <div 
                style={{
                    position:'fixed',
                    top:'0',
                    zIndex:'-5',
                    height:'100vh',
                    width:'100vw'
                }}
            className='background'>
                <BackGround/>
            </div>
            {/* define header here -- completed */}
            <div className='cen'>
                <Header/>
            </div>
            {/* define menu here -- not completed */}
           
            {/* define hero menu here -- images and canvas not added yet */}
            <div>
                <Hero/>
            </div>
            {/* dotted line for division -- make it appear*/}
            

            {/* Showcase what kind of work i do  */}

            {/* Web development first with horizontal scroll */}

            <Webdev/>
        </div>
    )
}