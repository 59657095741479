import { Canvas, useFrame } from '@react-three/fiber'
import './ProjectPage.css'
import { Environment, Image, ScrollControls, Stars, Text, useScroll } from '@react-three/drei'
import {  useEffect, useRef, useState } from 'react'
import { easing } from 'maath'
import { useDispatch, useSelector  } from "react-redux";
import * as THREE from 'three'

import {  setProject } from '../../State/Projects/ProjectSlice.tsx'

import * as projectDetail from '../../ProjectsJson/Projects.json'

import './util'

export default function ProjectPage(){

  const proj = useSelector((state) => state.project.projects) // initial projects . here we have [] i.e empty array initlly
  

    return(
        <>
            
                <p style={{ position: 'absolute', bottom: 50, left: 90, fontSize: '20px',zIndex:'10' }}>
                    Scroll Down
                </p>
                <div className='pro-detail'>
                    {/* project detail */}{projectDetail[proj.toString()]?.detail.title}
                    {projectDetail[proj.toString()]?.detail.points.map(e=><li>{e}</li>)}
                </div>
                <p className='pro-name' style={{cursor:'pointer'}} onClick = {()=>window.open(projectDetail[proj.toString()]?.link)}>
                    {projectDetail[proj.toString()]?.name}
                    <br/>
                    <p style={{textDecoration:'underline',fontWeight:'normal'}}>{projectDetail[proj.toString()]?.type}</p>
                </p>
            
            <div  className='projectPage' >
                <Canvas style={{zIndex:'5'}} camera={{ position: [0, 0, 100], fov:(window.innerHeight>window.innerWidth)?30: 20 }}>
                    <Stars/>
                    {/* 60 around for a phone */}
                    <fog attach="fog" args={['#a79', 8.5, 12]} />
                    <ScrollControls pages={1}>
                    <Rig rotation={[0, 0, 0.15]}>
                        <mesh position={[0,-2.5,0]}>
                            <meshLambertMaterial color={'#a79'}/>
                            <cylinderGeometry args={[0.3,0.3,7,30]}/>
                        </mesh>
                        <Carousel />
                    </Rig>
                    </ScrollControls>
                    <Environment preset="warehouse" background blur={0.5} />
                </Canvas>
            </div>
        </>
    )
}

function Rig(props) {
    const ref = useRef()
    const scroll = useScroll()
    useFrame((state, delta) => {
      ref.current.position.y = scroll.offset * 10
      ref.current.rotation.y = -scroll.offset * (Math.PI * 2) * 1.5 // Rotate contents
      state.events.update() // Raycasts every frame rather than on pointer-move
      easing.damp3(state.camera.position, [-state.pointer.x * 2, state.pointer.y + 1.5, 10], 0.3, delta) // Move camera
      state.camera.lookAt(0, 0, 0) // Look at center
    })
    return <group ref={ref} {...props} />
  }

  function Carousel({ radius = 3, count = Object.keys(projectDetail).length -1 }) {
    const proj = useSelector((state) => state.project.projects)
    const dispatch = useDispatch()
    return Array.from({ length: count }, (_, i) => (
      <mesh onClick={()=>dispatch(setProject(i))}>
        <Card
          key={i}
          url={projectDetail[i.toString()].photo}
          position={[Math.sin((i / count) * Math.PI * 2) * radius, -i *0.8, Math.cos((i / count) * Math.PI * 2) * radius]}
          rotation={[0, Math.PI + (i / count) * Math.PI * 2, 0]}
        />
      </mesh>
    ))
  }

  function Card({ url, ...props }) {
    const ref = useRef()
    const [hovered, hover] = useState(false)
    const pointerOver = (e) => (e.stopPropagation(), hover(true))
    const pointerOut = () => hover(false)
    useFrame((state, delta) => {
      easing.damp3(ref.current.scale, hovered ? 1.5 : 1, 0.1, delta)
      easing.damp(ref.current.material, 'radius', hovered ? 0.25 : 0.1, 0.2, delta)
      easing.damp(ref.current.material, 'zoom', hovered ? 1 : 1.5, 0.2, delta)
    })
    return (
      <Image ref={ref} url={url} transparent side={THREE.DoubleSide} onPointerOver={pointerOver} onPointerOut={pointerOut} {...props}>
        <bentPlaneGeometry args={[0.1, 1, 1, 20, 20]} />
      </Image>
    )
  }