import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import * as projectDetail from '../../ProjectsJson/Projects.json'

interface ProjectState{
    name : string;
    link : string;
    challanges: string[];
    stack : string [];
    img : string;
}

interface ProjectsState{
    projects : Array<ProjectState>
}

const projs : number = 0

//  export async function updateProject(url:string , data:Array<ProjectState>){
//    try{
//     await axios.post(url,{"message": {
//         "token": "aValidToken",
//         "webpush": {
//           "notification": {
//             "title": "New Content!",
//             "body": "A new video has been uploaded.",
//             "icon": "your_icon"
//           }
//         }
//       }},{
//         headers:{
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
//         }
//     })

//     // const data =  await axios.get(url,{headers:
//     // {
//     //         "Access-Control-Allow-Origin": "*",
//     //         "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
//     //     }
//     // })
//     // console.log(data.data)
//    }
//    catch(e){
//         console.log('we have an <error!!!></error!!!>')
//         console.log(e)
//    }
//  }

const initialProjects : any = {
    projects : projs
}

const projectSlice = createSlice({
    name:"projects",
    initialState:initialProjects,
    reducers:{
        setProject:(state,action)=>{
            const projects = action.payload
            return {projects}
        }
    }

})

// export const getProject = createAsyncThunk(
//     "projects/getProjects",
//     async()=>{
//         // await fetch data
//         // return data
//         const url: string  = "https://firestore.googleapis.com/v1/projects/delivery-282108/databases/(default)/documents/Work/LA"
//         await updateProject(url,projs)
//     }
// )

export const { setProject} = projectSlice.actions
export default projectSlice.reducer
