import './Hero.css'

export default function Hero(){
    return(
        <>
            <div className='hero-bottom'>
                <div className='hero-top'>
                    <p> Hello, my name is Harsh rai . I am a web ,  mobile and desktop app developer . </p>
                    <p style={{fontSize:'50%',padding:'0',margin:'0'}}>नमस्ते, मेरा नाम हर्ष राय है। मैं एक वेब, मोबाइल, और डेस्कटॉप एप्लिकेशन डेवेलपर हूँ।</p>
                    <div className='hero-cool'>
                        <div className='hero-cool-text'>
                            <p>(I make cool s**t with my pc!)</p>
                        </div>
                        <div className='hero-canvas'>
                            {/* canvas here */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}