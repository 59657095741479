import { AccumulativeShadows, Center, Environment, Html, MeshTransmissionMaterial, RandomizedLight, Text } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import { useEffect, useRef, useState } from "react";
import * as THREE from 'three'
import './ContactPage.css'

import * as emailJs from '@emailjs/browser'



export default function ContactPage(){

    const[email,setEmail] = useState('')
    const[phoneNumber,setNumber] = useState('')
    const[name,setName] = useState('')

    const [refreshed , setRefreshed] = useState(true)
    const [send , setSend] = useState('SEND')



    useEffect(()=>{
        setSend("SEND")
    },[email,phoneNumber])

    const sendData = async()=>{

        if(refreshed === false){
            setSend("Refresh!")
            return
        }

        var emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var phoneRegex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/
        if(!emailRegex.test(email)){
            setSend("Invalid Email")
            return
        }
        if(!phoneRegex.test(phoneNumber)){
            setSend("Invalid Number")
            return
        }

        await emailJs.init({
            publicKey:'y7dyYqK2qjJqXv4r7',
            blockHeadless:true,
            limitRate:{
                id:'app',
                throttle:'100000'
            }
        })

        var templateParams = {
            to_name:'Harsh',
            from_name:name,
            message:`His phone number is ${phoneNumber} , name : ${name} , Email : ${email}. Kindly contact him asap.`
        }

        try {
            await emailJs.send('service_tsclkjk','template_3fpaatj',templateParams)
            setSend("Thank You!")
            setRefreshed(false)
        } catch (error) {
            console.log(error)
            setSend("Try Again")
            setRefreshed(false)
        }



    }


    // useEffect(()=>{
    //     console.log(email)
    // },[email])

    function chooseEmail(email){
        setEmail(email)
    }

    function chooseNumber(number){
        setNumber(number)
    }

    function chooseName(name){
        setName(name)
    }


    return(
        <>
            <div style={{height:'100vh',width:'100vw'}}>
                <Canvas shadows camera={{ position: [1, 0.5, 50] , fov:(window.innerHeight>window.innerWidth)?110:90 }} >
                    <color attach="background" args={['#f0f0f']} />
                    <ambientLight intensity={1} />
                    <spotLight position={[10, 10, 10]} angle={0.5} penumbra={1} castShadow />
                    <pointLight position={[-10, 0, -10]} intensity={2} />
                    <Input type ={'name'} chooseName = {chooseName} text={'Enter Name'} scale={1} position={[-3, 3, -3]} />
                    <Input type = {'numb'} chooseNumber = {chooseNumber} text={'Enter Number'} scale={1} position={[2, 2, -2]} />
                    <Input type = {'mail'} chooseEmail = {chooseEmail} text={'Enter Email'} scale={1} position={[0.4, 0.25, 1]} />
                    <group position={[0, -1, -2]}>
                        <Center top position={[0, -0.1, 3]}>
                        <mesh onClick={()=>sendData()}><Model value={send} scale={0.8} /></mesh>
                        </Center>
                        <Sphere scale={0.25} position={[-3, 0, 2]} />
                        <Cylinder scale={0.25} position={[-4, 0, -2]} />
                        <Sphere scale={0.65} position={[3.5, 0, -2]} />
                        <Text position={[0, 12, -15]} font="/Inter-Regular.woff" color={'grey'} fontSize={2}>
                        CONTACT
                        <MeshTransmissionMaterial backside thickness={0.2} anisotropicBlur={0.1} chromaticAberration={0.1} clearcoat={1} />
                        </Text>
                        <AccumulativeShadows temporal frames={100} alphaTest={0.2} opacity={0.1} scale={12}> 
                         <RandomizedLight amount={8} radius={4} ambient={0.5} intensity={1} position={[2.5, 5, -10]} /> 
                         </AccumulativeShadows>
                    </group>
                    <Environment preset="studio" />
                    <Rig />
                </Canvas>
            </div>
        </>
    )
}


function Sphere(props) {
    return (
      <Center top {...props}>
        <mesh castShadow receiveShadow>
          <sphereGeometry args={[1, 64, 64]} />
          <meshStandardMaterial />
        </mesh>
      </Center>
    )
  }


  function Cylinder(props) {
    return (
      <Center top {...props}>
        <mesh castShadow receiveShadow>
          <cylinderGeometry args={[1, 1, 2, 32]} />
          <meshStandardMaterial />
        </mesh>
      </Center>
    )
  }

function Rig({ vec = new THREE.Vector3() }) {
    useFrame((state) => {
      state.camera.position.lerp(vec.set(0 + state.pointer.x / 2, 0.5 + state.pointer.y / 2, 5), 0.008)
      state.camera.lookAt(0, 0, 0)
    })
  }
  

function Model(props) {
    return (
      <mesh castShadow receiveShadow {...props}>
        <MeshTransmissionMaterial backside thickness={0.2} anisotropicBlur={0.1} chromaticAberration={0.1} clearcoat={1} />
        <Text position={[0, 0, 0]}> {props.value} </Text>
      </mesh>
    )
  }

function Input(props) {
    const [text, set] = useState(props.text)
    useEffect(()=>{
        function setText(){
           if(props.type === 'name'){
            props.chooseName(text)
           }
           else if(props.type === 'mail'){
            props.chooseEmail(text)
           }
           else if(props.type === 'numb'){
            props.chooseNumber(text)
           }
        }

        setText()
    },[text])
    return (
      <group {...props}>
        <Text position={[-1.2, -0.022, 0]} anchorX="0px" fontSize={0.335} letterSpacing={-0.0}>
          {text}
          <meshStandardMaterial color="black" />
        </Text>
        <mesh position={[0, -0.022, 0]} scale={[2.5, 0.48, 1]}>
          <planeGeometry />
          <meshBasicMaterial transparent opacity={0.3} depthWrite={false} />
        </mesh>
        <Html transform>
          <ControlledInput type={text} onChange={(e) => set(e.target.value)} value={text} />
        </Html>
      </group>
    )
  }



  export const ControlledInput = (props) => {
    const { value, onChange, ...rest } = props
    const [cursor, setCursor] = useState(null)
    const ref = useRef(null)
    useEffect(() => {
      const input = ref.current
      if (input) input.setSelectionRange(cursor, cursor)
    }, [ref, cursor, value])
    const handleChange = (e) => {
      setCursor(e.target.selectionStart)
      onChange && onChange(e)
    }
    return <input ref={ref} value={value} onChange={handleChange} {...rest} />
  }
  