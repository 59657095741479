import { Canvas, extend, useFrame } from '@react-three/fiber'
import * as THREE from 'three'
import './BackGround.css'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FilmPass, WaterPass, UnrealBloomPass, LUTPass, } from 'three-stdlib'
import { Effects } from '@react-three/drei'

extend({ WaterPass, UnrealBloomPass, FilmPass, LUTPass })

export default function BackGround(){
    return(
        <Canvas linear flat legacy dpr={1} camera={{ fov: 60, position: [0, 0, 25] }}>
            <mesh>
                {/* make a dark plane */}
                <planeGeometry args={[1000,1000]}/>
                {/* add material to it */}
                <meshStandardMaterial color=' #00004d' roughness={0.5} depthTest={false}/>
            </mesh>
            <Swarm count={2000}/>
            <Postpro/>
        </Canvas>
    )
}

function Swarm({count, dummy = new THREE.Object3D()}){
    const light =  useRef()
    const mesh = useRef()
    const [mousePos,setmousePos] = useState({x:0,y:0})

    useEffect(() => {
        const handleMouseMove = (event) => {
          // Get mouse position
        // const rect = event.target.getBoundingClientRect();
        const mouseX = (event.clientX / window.innerWidth) * 2 -1 ;
        const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;

    
          // Log mouse position
          console.log('Mouse Position:', { x: mouseX, y: mouseY });
          setmousePos({x:mouseX,y:mouseY})
        };
    
        // Add event listener for mousemove
        window.addEventListener('mousemove', handleMouseMove);
    
        // Cleanup: Remove event listener on component unmount
        return () => {
          window.removeEventListener('mousemove', handleMouseMove);
        };
      }, []); 
      const particles = useMemo(() => {
        const temp = []
        for (let i = 0; i < count; i++) {
          const t = Math.random() * 100
          const factor = 20 + Math.random() * 100
          const speed = 0.01 + Math.random() / 200
          const xFactor = -50 + Math.random() * 100
          const yFactor = -50 + Math.random() * 100
          const zFactor = -50 + Math.random() * 100
          temp.push({ t, factor, speed, xFactor, yFactor, zFactor, mx: 0, my: 0 })
        }
        return temp
      }, [count])

    useFrame((state)=>{
        light.current.position.set(mousePos.x * state.viewport.width/5,mousePos.y * state.viewport.height/5,0)
        particles.forEach((particle, i) => {
            let { t, factor, speed, xFactor, yFactor, zFactor } = particle
            t = particle.t += speed / 2
            const a = Math.cos(t) + Math.sin(t * 1) / 10
            const b = Math.sin(t) + Math.cos(t * 2) / 10
            const s = Math.cos(t)
            particle.mx += (state.pointer.x * 1000 - particle.mx) * 0.01
            particle.my += (state.pointer.y * 1000 - 1 - particle.my) * 0.01
            dummy.position.set(
              (particle.mx / 10) * a + xFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 1) * factor) / 10,
              (particle.my / 10) * b + yFactor + Math.sin((t / 10) * factor) + (Math.cos(t * 2) * factor) / 10,
              (particle.my / 10) * b + zFactor + Math.cos((t / 10) * factor) + (Math.sin(t * 3) * factor) / 10
            )
            dummy.scale.setScalar(s)
            dummy.rotation.set(s * 5, s * 5, s * 5)
            dummy.updateMatrix()
            mesh.current.setMatrixAt(i, dummy.matrix)
          })
          mesh.current.instanceMatrix.needsUpdate = true
    })
    return(
        <>
            {/* declare a circular pointlight */}
            <pointLight ref={light} distance={0} intensity={500} color='#ccccff' position={[0,0,0]}>
                <mesh scale={[1,1,6]}>
                    <sphereGeometry args={[0.01,16,16]}/>
                </mesh>
            </pointLight>
            {/* a mesh of similar material that is a sphere with count equal to item*/}
            <instancedMesh ref={mesh} args={[null,null,count]}>
                <sphereGeometry args={[0.1,16,16]}/>
                <meshStandardMaterial color='#ccccff' roughness={1}/>
            </instancedMesh>
        </>
    )
}


function Postpro() {
    const water = useRef()
    // const data = useLoader(LUTCubeLoader, '/cubicle.CUBE')
    useFrame((state) => (water.current.time = state.clock.elapsedTime * 4))
    return (
      <Effects disableGamma>
        <waterPass ref={water} factor={1} />
        <unrealBloomPass args={[undefined, 1.25, 1, 0]} />
        <filmPass args={[0.2, 0.5, 1500, false]} />
        {/* <lUTPass lut={data.texture} intensity={0.75} /> */}
      </Effects>
    )
  }