
import './App.css';
import MainPage from './Page/MainPage/MainPage';

function App() {

  return (
    <div className="App">
        <MainPage/>
    </div>
  );
}

export default App;
