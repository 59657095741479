import { useEffect, useState } from 'react'
import './Header.css'

export default function Header(){

    const [date , setDate] = useState('00/00/0000')
    const [time , setTime] = useState('00:00')


    useEffect(()=>{
        const d  = new Date()
        setDate(d.toString().substring(0,15))
    },[])

    useEffect(()=>{
        
        const timer = setInterval(() => { // Creates an interval which will update the current data every minute
            // This will trigger a rerender every component that uses the useDate hook.
            const d1 = new Date()
            setTime(`${d1.getHours()}:${d1.getMinutes()}:${d1.getSeconds()}`) 
          }, 1000);
          return () => {
            clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
          }
    },[])

    return(
        <>
            <header className="header">
                <div style={{fontWeight:'bolder',fontSize:'120%'}}>Harsh Rai</div>
                <div className='headertime'>{date} ... {time}</div>
            </header>
        </>
    )
}

// header css and functions all done