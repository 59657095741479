import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "../../App";
import ProjectPage from "../ProjectPage/ProjectPage";
import ContactPage from "../ContactPage/ContactPage";
import { Provider } from "react-redux";
import {Store} from '../../State/Store.ts'



export default function Routerenderer(){
    const router = createBrowserRouter([
        {
          path: "/",
          element: <App />,
        },
        {
          path:"/projects",
          element:<ProjectPage/>
        },
        {
          path:"/contact",
          element: <ContactPage/>
        }
      ]);


      return(

        <Provider store={Store}><RouterProvider router={router}/></Provider>
      )
}